import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as ProductTableName } from '@spa-ec/components/ProductTable/ProductTable.theme';
import { ProductShelfCategoryKey } from '@spa-core/store/products/constants';
import { NAME as ProductRowName } from '@spa-ec/components/ProductTable/Rows/ProductRow.theme';
import { NAME as PrisjaktCheapestName } from '@spa-ec/components/PrisjaktCheapest/PrisjaktCheapest.theme';
import { NAME as SiteLogoName } from '@spa-ec/components/Header/HeaderSiteLogo/SiteLogo.theme';
import { NAME as CardDescriptionName } from '@spa-ec/components/Card/CardDescription/CardDescription.theme';
import { NAME as StickerName } from '@spa-ec/components/Sticker/Sticker.theme';
import { NAME as ShipmentAddressFormName, } from '@spa-ec/components/Checkout/AddressSection/ShipmentAddressForm.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    sideContentWidth: '22%',
    sideContentPadding: 'px-4',
};
const pageTitleTheme = {
    noBorder: true,
    fg: theme.col.tertiary.fg,
    bg: 'white',
    showLogoutIcon: false,
    combineSections: true,
    bottomBorderColor: '#343434',
};
const cartTableTheme = {
    showPerKronaInCartTable: false,
};
const productTableTheme = {
    categoriesOrder: [
        ProductShelfCategoryKey.ONE_BUY_PRODUCTS,
        ProductShelfCategoryKey.OPTIMUM,
        ProductShelfCategoryKey.RECOMMENDED,
        ProductShelfCategoryKey.BUDGET,
        ProductShelfCategoryKey.ORIGINAL,
        ProductShelfCategoryKey.UPSELLING,
        ProductShelfCategoryKey.FREE_CATEGORIES_LIST,
    ],
};
const productRowTheme = {
    showOneKronaSticker: true,
};
const shipmentAddressFormTheme = {
    showShippingAddressCellphone: true,
};
const prisjaktCheapestTheme = {
    storeIds: { 'nordicink-se': 3535, 'nordicink-no': 27700, 'nordicink-fi': 37251 },
};
const siteLogoTheme = {
    siteLogo: {
        imagesRootPath: '/images/sitelogo/common',
        paths: {
            b2c: {
                se: {
                    sv: '/nordicink_logo_v2.svg',
                },
                fi: {
                    fi: '/nordicink_logo_v2.svg',
                },
                no: {
                    no: '/nordicink_logo_v2.svg',
                },
                default: '/nordicink_logo_v2.svg',
            },
            b2b: {
                default: '/nordicink_logo_v2.svg',
            },
        },
    },
};
const cardDescriptionTheme = {
    hideShortCopyText: true,
};
const stickerTheme = {
    showOneKronaSticker: true,
    stickers: {
        se: '1kr_drop_sticker_nordicink.png',
        no: '0kr_drop_sticker_nordicink_NO.png',
        fi: '0e_drop_sticker_nordicink_FI.png',
        default: '1kr_drop_sticker_nordicink.png',
    },
};
export default {
    [ContentPageName]: contentPageTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [ProductTableName]: productTableTheme,
    [ProductRowName]: productRowTheme,
    [PrisjaktCheapestName]: prisjaktCheapestTheme,
    [SiteLogoName]: siteLogoTheme,
    [CardDescriptionName]: cardDescriptionTheme,
    [StickerName]: stickerTheme,
    [ShipmentAddressFormName]: shipmentAddressFormTheme,
};
